@import "@/styles/base";

.gaming-card-forged {
  width: min(366px, 100vw);
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  align-items: center;

  & .gamer-card {
    width: 100%;
  }

  &__heading {
    color: #fff;
    text-align: center;
    font-family: var(--font-integral-cf);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 100% */
  }
}
