.quiz-options {
  &__result {
    position: relative;
    isolation: isolate;
    display: flex;
    align-items: center;
    border-radius: radius(1);
    overflow: hidden;
    padding: spacing(2);

    .option-label {
      text-align: center;
      display: flex;
      justify-content: space-between;
    }

    &.selected {
      .check-icon {
        stroke: theme(status, success);
      }
    }

    // Make the button span act as a block
    > span {
      display: block !important;
    }

    &.disabled {
      opacity: 1;
    }

    & .btn-fill {
      position: absolute;
      height: 100%;
      inset: 0;
      z-index: -1;
    }
    & .winner {
      background-color: var(--community-color, theme(neutral, 16));
      border-radius: radius(1);
    }
    & .loser {
      background-color: var(--community-color, theme(neutral, 16));
      filter: brightness(50%);
      border-radius: radius(1);
    }
  }
}
