.switch {
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.02);
    border-radius: radius(2);
    display: block;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      background: theme(status, success);
      border-radius: radius(1);
      transition: theme(animation, duration);
    }

    &:active:after {
      width: 25px;
    }
  }

  input {
    &:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  &.size-small {
    label {
      width: 40px;
      height: 20px;

      &:after {
        width: 16px;
        height: 16px;
      }

      &:active:after {
        width: 25px;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.inactive {
    label:after {
      background-color: theme(neutral, 25);
    }
  }
}
