.inventory-toggle {
  padding: spacing(1);
  border-radius: radius(1);
  background-color: theme(neutral, 8);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: spacing(1);

  &__item {
    margin-right: spacing(4);
  }
  &--selected {
    background-color: rgba(255, 255, 255, 0.03);

    & svg > path {
      stroke: white;
    }
  }
}
