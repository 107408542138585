.page-error {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: spacing(2.5);

  h1.lexend-heading {
    font-weight: 700;
  }

  @media (max-width: $sm-max) {
    flex-direction: column;
    gap: spacing(5);
  }

  .status-code-section {
    position: relative;
    width: 400px;
    height: 340px;

    @media (max-width: $xs-max) {
      width: 100%;
      height: 100%;

      max-width: 400px;
      max-height: 340px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .status-code {
      width: 100%;
      padding-right: spacing(7.5);
      position: absolute;
      text-align: right;
      font-family: var(--font-lexend);
      font-weight: 700;
      font-size: 12.5rem;
      line-height: 12.5rem;
      color: #22222d;

      @media (max-width: $sm-max) {
        text-align: center;
        padding-right: 0;
      }

      @media (max-width: $xs-max) {
        font-size: 43vw;
      }
    }
  }

  .redirect-section {
    gap: spacing(3);
    max-width: 300px;

    @media (max-width: $sm-max) {
      text-align: center;
    }

    .redirect-btn {
      max-width: 214px;
      margin-top: spacing(6);

      @media (max-width: $sm-max) {
        max-width: 100%;
      }
    }
  }
}
