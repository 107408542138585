.flex {
  display: flex;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.between {
    justify-content: space-between;
  }

  &.middle {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.top {
    align-items: flex-start;
  }

  .expand {
    flex: 1;
  }
  &.items-stretch {
    align-items: stretch;
  }
}

.max-content {
  width: max-content;
  min-width: max-content;
}

.text-center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.disabled-feature {
  pointer-events: none;
  opacity: 0.4;
}

.cursor-pointer {
  cursor: pointer;
}


// Define a map for size-factor association
$gap-sizes: (
  extraSmall: 1,
  small: 2,
  normal: 4,
  large: 6
);

@include gap-generator($gap-sizes);