.landing__playground {
  padding: spacing(18) 0 spacing(36);

  video {
    margin-left: -15%;
    width: 80%;
    pointer-events: none;
    transform: translateX(8%);
  }

  .content {
    width: 50%;
    margin-left: -15%;
  }

  .integralfc-heading-lg {
    margin-bottom: spacing(6);
  }

  .lexend-heading-m {
    color: #868688;
    font-weight: 300;
    max-width: 509px;
  }

  .get-started {
    margin-top: spacing(8);
  }

  @media (max-width: $sm-max) {
    .container {
      flex-direction: column;
    }

    .content {
      width: auto;
      margin-left: 0;
    }

    video {
      width: auto;
      height: 369px;
      transform: translateX(0);
    }
  }
}
