.image-submission {
  display: flex;
  flex-direction: column;
  gap: spacing(6);

  & > :empty {
    display: none;
  }

  svg {
    stroke: theme(neutral, 50);
  }

  
  @include tablet-up {
    .quest-data {
      width: 50%;
    }
  }

  .in-review {
    opacity: 0.5;
  }
}
