.leaderboard-list-error {
  flex: 1;
  max-width: 476px;
  align-self: center;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 200%;
    z-index: -1;
  }

  .lexend-body-m {
    font-weight: 700;
    margin-top: spacing(6);

    a {
      color: theme(neutral, 50);
      text-decoration: none;
    }
  }

  .images-container {
    position: relative;
    max-width: 100%;
  }

  .btn {
    margin-top: spacing(8);
  }

  img {
    object-fit: contain;
    width: 30%;
    height: auto;
    aspect-ratio: 1/1s;

    &:first-child {
      scale: 0.8;
    }

    &:nth-child(2) {
      scale: 1;
    }

    &:nth-child(3) {
      scale: 0.65;
    }
  }
}
