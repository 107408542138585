@import "@/styles/base";

.request-data-popup {
  max-width: 420px;

  .email-input {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
  }

  .popup-title {
    margin-bottom: spacing(1);
    color: white;
  }

  p {
    margin-top: spacing(3);
    margin-bottom: spacing(6);
    color: theme(neutral, 50);
    font-weight: 500;
  }

  .input-label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  .send {
    margin-top: spacing(6);
  }
}
