.quest-requirements {
  display: flex;
  flex-direction: column;
  gap: spacing(4);

  .quest-requirement {
    display: flex;
    background: rgba(255, 255, 255, 0.04);
    padding: spacing(4);
    border-radius: radius(1);
    position: relative;

    &.fulfilled {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: theme(status, success);
      }
    }

    .requirement-title {
      font-weight: 700;
    }

    .requirement-desc {
      margin-top: spacing(2);
      font-weight: 500;
      color: theme(neutral, 50);
    }

    .action {
      padding: spacing(2);
    }

    @include mobile-only {
      flex-direction: column;
      gap: spacing(4);

      .requirement-action, .requirement-action button {
        width: 100%;
      }
    }
  }
}

@import './steam/LinkSteam/LinkSteam';

@import './riot/LinkRiot/LinkRiot';

@import './general/ReferralCount/ReferralCount';
@import './general/Quiz/QuizQuest';
@import './general/Quiz/QuizOptions';
@import './general/AnswerSubmission/AnswerSubmission';
@import './general/VisitLinkMission/VisitLinkMission';

@import './manual/ImageSubmission/ImageSubmission';
@import './manual/TextSubmission/TextSubmission';
