.landing__events {
  background-color: #13131a;
  padding: spacing(22.5) 0;
  position: relative;
  z-index: 1;

  .integralfc-heading-lg {
    padding-bottom: spacing(14);
  }

  .list {
    margin: 0 spacing(-2);
  }

  .event-container {
    padding: 0 spacing(2);
  }

  .single-event {
    height: 100%;
  }

  @media (max-width: $md-max) {
    .list {
      flex-direction: column;
    }

    .integralfc-heading-lg {
      text-align: left;
    }

    .event-container {
      margin-bottom: spacing(4);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
