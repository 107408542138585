@import "@/styles/base";

.input_container {
    position: relative;
    isolation: isolate;
    display: flex;
    flex: 1 1 0;
    align-items: flex-start;
    width: 100%;
    z-index: 0;
    justify-content: flex-start;
    border-radius: radius(1);
    padding: spacing(4);
}

.error {
    color: theme(status, error);
}

.variant_filled {
    background-color: theme(neutral, 8);

     & input {
        background-color: transparent;
        border: none;
        font-weight: 400;
        font-size: 16px;
        font-family: var(--font-lexend);
        color: theme(neutral, 50);
    }
}

.variant_outlined {
    background-color: theme(background, component);
    border: 1px solid theme(neutral, 8);
    display: flex;
    gap: spacing(3);
    align-items: center;
    padding: spacing(3);
    
    & input {
        background-color: transparent;
        border: none;
        font-weight: 500;
        font-size: 16px;
        font-family: var(--font-lexend);
        color: theme(neutral, 25);
    }
}