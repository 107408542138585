.alert-background {
  padding: spacing(2);
  background: theme(status, in-progress);
  border-radius: radius(1);

  svg {
    stroke: theme(neutral, 50);
    margin-right: spacing(2);
  }

  &-error {
    background: theme(status, error);
    color: theme(text-color, main);
  }

  &-warning {
    background: theme(status, warning);
    color: theme(text-color, main);
  }

  &-success {
    background: theme(status, success);
    color: theme(neutral, 8);

    svg {
      stroke: theme(neutral, 8) !important;
    }
  }
}

.alert-simple {
  svg {
    stroke: theme(status, in-progress);
    margin-right: spacing(2);
  }

  &-error {
    svg {
      stroke: theme(status, error) !important;
    }
  }

  &-warning {
    svg {
      stroke: theme(status, warning) !important;
    }
  }
}
