@import '@/styles/base';

.quest-complete-popup {
  padding: spacing(8);

  .btn-transparent {
    width: 300px;
    height: 72px;
  }

  .reward-container {
    width: 290px;
    min-height: 290px;
    background-color: rgba(0, 0, 0, 0.02);
    margin: spacing(8) 0;
  }

  .wearable-container {
    margin: spacing(12) 0;

    .reward-image {
      height: 280px;
    }
  }

  .reward {
    margin-top: spacing(4);
  }

  .reward-image {
    height: 180px;
    position: relative;

    .image {
      height: 100%;
      width: auto;
    }

    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: auto;
      filter: blur(16px);
      z-index: -1;
    }
  }

  .tags-list {
    margin-top: spacing(2);
  }

  .particles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
  }

  .wearable-unlocked {
    margin-top: spacing(2);
    color: #20a2ff;
  }

  .close {
    background-color: transparent;
    border: none;
    padding: spacing(4);
  }

  .border {
    box-shadow: 0 0 10px 0 var(--community-color, inherit);
    background: var(--community-color, #20a2ff);
  }
}
