.single-quest {
  --single-quest-background: #{theme(background, component)};
  padding: spacing(10) spacing(6);
  color: theme(neutral, 50);
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  position: relative;
  height: 156px;
  gap: spacing(4);
  isolation: isolate;

  &:hover {
    --single-quest-background: #1C1C25;
  }

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    border-radius: calc(#{radius(2)} + 1px);
    z-index: -1;
    pointer-events: none;
    background-color: var(--single-quest-background);
  }

  &-shadow {
    --border-width: -1px;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    right: var(--border-width);
    bottom: var(--border-width);
    z-index: -1;
    border-radius: calc(radius(2) + 2px); // 2px should be added to keep the rounded corners for padding
    background: linear-gradient(
      222deg,
      var(--community-color, #20a2ff) 0%,
      theme(neutral, 25) 42%,
      theme(neutral, 16) 100%
    );
    pointer-events: none;
  }

  .quest-title {
    color: theme(text-color, main);
  }

  .discord {
    fill: #4e32ff;
  }


  .tags-list {
    margin-right: spacing(2);

    .single-tag {
      margin-right: spacing(2);
    }
  }

  .quest-reward {
    position: relative;
    flex-shrink: 0;
    width: 72px;
    aspect-ratio: 1/1;

    &--xp {
      width: 32px
    }
  }

  .reward-text {
    color: theme(neutral, 100);
  }

  .quest-description {
    position: relative;
    max-width: 70%;
  }
  

  .super:empty  {
    display: none;
  }
  .title {
    margin-top: spacing(1);
  }

  .description {
    color: theme(neutral, 50);
    font-weight: 300;
    font-size: 14px;
    max-height: 32px;
    overflow: hidden;

    .tag-link {
      color: #868688;
      text-decoration: none;
      pointer-events: none;
    }
  }

  .quest-icon {
    margin-left: auto;
  }

  &.empty {
    .single-quest-shadow {
      background: rgba(45, 45, 49, 1);
    }
  }

  .locked {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: radius(2);
    background: rgba(20, 20, 27, 0.85);
    backdrop-filter: blur(4px);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    padding: spacing(6);

    .locked-text,
    svg {
      transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      backdrop-filter: blur(0);

      .locked-text,
      svg {
        opacity: 0;
      }
    }

    svg {
      stroke: #fff;
      margin-right: spacing(4);
    }
  }

  .reward-progress-overlay {
    position: absolute;
    inset: 0;
    opacity: 0;
    border-radius: radius(2);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: crosshair;

    &__detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__resource {
      width: 32px;
      aspect-ratio: 1/1;
      position: relative;
      display: inline-block;
    }
  }
}

