@import '@/styles/base';

.logout-popup {
  width: 250px;

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: spacing(8);
  }

  .actions {
    .btn-red {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      font-family: var(--font-lexend);
      white-space: nowrap;

      &:first-child {
        margin-right: spacing(2);
      }
      &:last-child {
        margin-left: spacing(2);
      }
    }
  }
}

.site_light {
  .logout-popup {
    .modal-title {
      color: theme(neutral, 2);
    }
  }
}
