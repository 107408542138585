.quest-status-gaming {
  .circle {
    width: 8px;
    height: 8px;
    margin-right: spacing(1);
    border-radius: 50%;
    background-color: theme(neutral, 50);
  }

  span {
    font-weight: 500;
    color: theme(neutral, 50);
  }

  &.resolving,
  &.active {
    .circle {
      background-color: theme(status, warning);
    }

    span {
      color: theme(neutral, 50);
    }
  }

  &.completed {
    .circle {
      background-color: theme(rarity, rare);
    }

    span {
      color: theme(neutral, 50);
    }
  }
}
