.countdown {
  position: relative;
  font-weight: 700;

  .countdown-title {
    margin: 0 4px 0 2px;
    font-weight: 400;
    color: theme(neutral, 25);
  }
}
