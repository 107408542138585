@import "@/styles/base";

.popup {
  width: 100%;
  position: fixed;
  z-index: 1001;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: radius(3);
  padding: spacing(6);
  background-color: theme(background, component);

  @media (max-width: $sm-max) {
    max-height: 100%;
  }
}

.popup_inner {
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(16px);
  padding: spacing(8);
  position: relative;
  border-radius: radius(3);
  width: 681px;
  max-width: 681px;

  @include mobile-only {
    width: 100vw;
    height: 100vh;
  }

  .popup-title {
    letter-spacing: 0;
    color: theme(neutral, 50);
    font-weight: 700;
  }

  .close-popup {
    transition: theme(animation, duration) theme(animation, timing);

    &:hover {
      transform: scale(1.3) rotate(3deg);
    }

    svg path {
      stroke: theme(brand, main);
    }
  }

  .close-popup-square {
    border-radius: radius(1);
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    position: relative;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }

    svg {
      path {
        stroke: theme(neutral, 50);
      }
    }
  }
}

.popup_underlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 13, 18, 0.95); /* #0D0D12 with 95% opacity */
  backdrop-filter: blur(8px);
  z-index: -1;
  opacity: 0;
  transition: theme(animation, duration) theme(animation, timing);
}

.page_popups_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  isolation: isolate;
}

.container_open {
  display: flex;
  z-index: 4;
}
.container_closed {
  display: none;
  z-index: 0;
}
.popup_state_open {
  opacity: 1;
}

.popup_fullscreen {
  max-width: 100%; 
  width: 100vw;
  height: 100dvh;
}

.popup_login {
  max-width: 510px; 
  padding: 0;
  overflow: hidden;

  @include mobile-only {
    border-radius: 0;
  }
}