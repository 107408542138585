.quests-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-auto-rows: 1fr;
  gap: spacing(6);
  align-content: stretch;

  @include large-desktop-up {
    grid-template-columns: repeat(2, 1fr);
  }
}
