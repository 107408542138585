.input-label {
  font-family: var(--font-lexend);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.05em;
  margin-bottom: spacing(0.5);

  span {
    color: theme(neutral, 25);
  }
}


@import './styles/Switch';
@import './styles/CodeInput';
@import './styles/Dropdown';
@import './styles/SwitchButton';
@import './styles/TextArea';
@import './Checkbox/Checkbox';
