.collapsible-container {
  position: relative;

  .collapsible-tag {
    position: absolute;
    top: 32px;
    left: 32px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding: spacing(2);
    background-color: #212121;
    z-index: 2;
  }

  &.with-tag {
    .Collapsible__trigger {
      padding-top: spacing(10.5);
    }
  }

  .Collapsible {
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.04);
    padding: spacing(8);
    position: relative;
    margin-bottom: spacing(4);

    &__trigger {
      cursor: pointer;
      width: 100%;
      display: flex;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      padding-right: spacing(6.75);
      color: theme(neutral, 50);

      &:after {
        content: '';
        position: absolute;
        right: 32px;
        top: 32px;
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        background: url('/images/arrow-square-down.png');
        background-size: cover;
        transition: 0.2s ease-in;
      }

      &.is-open {
        &:after {
          transform: rotate(0);
        }
      }
    }

    &__contentInner {
      margin-top: spacing(4);
      color: rgba(252, 252, 252, 0.5);

      p {
        margin-bottom: spacing(8);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .link {
        text-decoration: underline;

        &:hover {
          color: theme(brand, main);
        }
      }

      b {
        text-decoration: underline;
      }

      strong {
        font-weight: 800;
      }

      ul li {
        list-style-type: disc;
      }
    }
  }

  @media (max-width: $sm-max) {
    .Collapsible {
      padding: spacing(3);

      &__trigger {
        font-size: 16px;
        line-height: 28px;

        &:after {
          right: 12px;
          top: 16px;
        }
      }
    }

    &.with-tag {
      .Collapsible__trigger {
        padding-top: spacing(6.5);
      }
    }

    .collapsible-tag {
      left: 12px;
      top: 12px;
      font-size: 9px;
      line-height: 13px;
      padding: spacing(1) spacing(2);
    }
  }
}

.site_light {
  .Collapsible {
    background-color: theme(neutral, 50);

    .Collapsible__trigger,
    .Collapsible__contentInner p {
      color: theme(neutral, 2);
    }
  }
}
