.checkbox__container {
  position: relative;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .input__label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    margin: 0;

    &:before {
      content: '';
      border-radius: radius(1);
      border: 1px solid rgba(255, 255, 255, 0.16);
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      width: spacing(6);
      height: spacing(6);
      margin-right: spacing(2);
      transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  .input__check {
    display: none;
  }

  &:hover {
    .input__label {
      &:before {
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }

  &.is-checked {
    .input__label {
      &:before {
        border: 1px solid theme(status, success);
        background: theme(status, success);
      }
    }

    .input__check {
      display: flex;
      position: absolute;
      top: spacing(1.75);
      left: spacing(1.75);
      width: spacing(3);
      height: spacing(3);
      stroke: #000;
    }
  }
}
