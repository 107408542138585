.loader-page {
  height: 100vh;
  width: 100%;
  background-color: theme(background, component);

  video {
    width: 64px;
    height: 64px;
  }
}
