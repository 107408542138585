@import '@/styles/base';

$apeironBgColor: rgb(225, 167, 73); // APEIRON SPECIFIC COLOR

.apeiron {
    display: flex;
    flex-direction: column;
    gap: spacing(12);

    &__heading {
        font-family: var(--font-integral-cf);
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 2.5rem;
        text-align: center;
    }
    &__card {
        background: linear-gradient(225.91deg, rgba(255, 255, 255, 0.1) 2.37%, rgba(255, 255, 255, 0) 61.82%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
        padding: spacing(8);
        border-radius: radius(2);
        gap: spacing(3);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__body {
        font-family: var(--font-lexend);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
    }
    &__button_wrapper {
        display: flex;
        justify-content: center;
    }
    &__button {
        background-color: $apeironBgColor;

        &:hover {
            background-color: darken($apeironBgColor, 10);
        }
    }
    &__form {
        display: flex;
        gap: spacing(2);
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    &__input {
        padding: spacing(4) spacing(3);
        background-color: rgba(theme(text-color, main), 0.04);
        border: none;
        border-radius: radius(2);
        color: theme(text-color, main);
        transition: all 1s ease;

        &_error {
            border: 1px solid red;
            color: red;
        }
        &_wrapper {
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            width: 100%;
        }
    }
    &__error {
        color: red;
        font-family: var(--font-lexend);
        font-size: 0.75rem;
        text-align: left;
    }
}