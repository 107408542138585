.event-quests {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  
  .tags-list {
    background: theme(background, component);
    display: flex;
    align-items: center;
    border-radius: radius(2);
    gap: spacing(2);
    justify-content: flex-start;

    .single-tag {
      padding: spacing(2) spacing(3);
      font-weight: 500;
      color: transparentize(theme(neutral, 100), 0.75);
      @include small-mobile-only {
        font-size: 12px;
        flex-grow: 1;
        max-height: 2rem;
      }
    }
    .single-tag.active {
      color: theme(neutral, 100);
      &:before {
          opacity: 0;
      }
    }

    @include small-mobile-only {
      border-radius: unset;
      gap: spacing(1);
      justify-content: space-between;
    }
  }
}