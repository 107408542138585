.quest-rewards-container {
  padding: 0;

  @include mobile-only {
    display: none;
  }

  .quest-reward {
    width: 50%;
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.04);
    padding: spacing(6);
    margin-bottom: 0 !important;

    .reward-name {
      font-weight: 700;
      margin-bottom: 0;
    }

    svg {
      fill: theme(neutral, 50);
    }
    &--xp {
      width: 32px;
    }
  }

  .no-reward,
  .loading {
    height: 80px;
    width: 80px;
  }
}
