@import '@/styles/base';

.claim-username-popup {
  .modal-title {
    font-weight: 700;
    margin-bottom: spacing(8);
  }

  .input-wrapper {
    margin-bottom: spacing(14);

    input {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      width: 100%;
    }
  }

  .actions {
    .btn-red {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      font-family: var(--font-lexend);
      white-space: nowrap;
    }
  }
}
