
.container {
  max-width: $container-max-width;
  width: 100%;
  margin: 0 auto;

  &.container-xl {
    max-width: $container-breakpoint-xl;
    padding: 0 spacing(5);
  }

  &.container-lg {
    max-width: $container-breakpoint-lg;
    padding: 0 spacing(5);
  }

  &.container-l {
    max-width: $container-breakpoint-l;
    padding: 0 spacing(4);
  }

  &.spacing-bottom {
    margin-bottom: spacing(20);
  }

  @include tablet-up {
    padding: 0 spacing(2) 0 spacing(20);
  }
  
  @media (max-width: $md-max) {
    max-width: 100%;
  }
}

.nav-visible {
  .container {
    padding: 0 spacing(10);
  }

  .page-spacer {
    margin-top: theme(sizes, navbar-height);
  }
}

.nav-hidden {
  .container {

  }
}
