@import "@/styles/base";

.navbar {
    display: flex; 
    z-index: 99;
    padding: spacing(3) spacing(4);
    background-color: theme(background, component);
    width: 100svw;
    position: fixed;
    top: 0;
    justify-content: space-between;

    @include desktop-up {
        display: none;
    }
}

.primary_section {
    display: flex; 
    gap: spacing(4);
    align-items: center;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 0;
    background-color: rgba(theme(neutral, 2), 0.2);
    backdrop-filter: blur(16px);
    display: block;
    transition: all 1s ease;
    z-index: 98;
}

.sidebar-menu-links {
    z-index: 999;
}

.vertical_navbar_container {
    width: var(--sidebar-width);
    position: fixed; 
    left: 0;
    top: 56px;
    bottom: 0;
    background-color: theme(background, component);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: spacing(4);
    z-index: 100;
}

.appName {
    display: flex;
    gap: spacing(1);
    align-items: center;
    font-family: var(--font-integral-cf);
    font-size: 12px;
}

.close_icon {
    stroke: theme(text-color, main);
    opacity: 1;
}