@import '@/styles/base';

.sidebar {
  --sidebar-width: #{theme(sizes, sidebar)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  width: var(--sidebar-width);
  background-color: theme(background, component);
  padding: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: spacing(6) spacing(4);

  @include mobile-only {
      display: none;
      &--visible {
        display: block;
      }
  }
}

.upper_section {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  align-items: center;
}