@import "@/styles/base";

.unlink-account-popup {
  width: 420px;

  .popup-title {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(6);
    color: theme(neutral, 25);
    font-weight: 500;
  }

  .input-label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  input {
    text-align: center;
  }

  .actions {
    margin-top: spacing(6);
  }

  .cancel {
    margin-right: spacing(2);
  }
}
