@import '@/styles/base';

.icon_button {
    padding: spacing(1);
    border-radius: radius(1);
    outline: none;
    border: none;
    cursor: pointer;
    aspect-ratio: 1/1;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.solid {
    background-color: theme(neutral, 8);
    &:hover {
        background-color: theme(neutral, 16);
    }
}

.outlined {
    background-color: transparent;
    border: 1px solid theme(neutral, 8);

    &:hover {
        border: 1px solid theme(neutral, 16);
    }
}

.transparent {
    background-color: transparent;

    &:hover {
        filter: brightness(85%);
    }
}
.primary {
    --background: #{theme(brand, main)};
    background-color: var(--background);

    &:hover {
        filter: brightness(85%)
    }
}