@import "@/styles/base";

.quest_details {
  height: 100%;
  border-radius: radius(3);
  display: flex;
  flex-direction: column;

  @include mobile-only {
    border-radius: 0;
    

    .gaming-requirement {
      flex-wrap: wrap;

      .stats-list {
        width: 100%;
        margin-left: 0;
        margin-top: spacing(4);
      }

      .objectives-list {
        margin-left: 0;
        margin-top: spacing(4);
        width: 100%;
      }
    }

    .requirements {
      margin-top: spacing(4);
    }
  }
}

.quest_details_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: spacing(8);
}

.quest_subtitle {
  color: theme(neutral, 50);
  margin-bottom: spacing(6);
}

.popup_border {
  position: absolute;
  inset: 0;
  border-radius: radius(3);
  padding: spacing(0.25);
  background: linear-gradient(258.12deg, transparentize(theme(neutral, 100), 0.84) 45.85%, transparent 100%);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 3;

  @include mobile-only {
    display: none;
  }
}

.quest_details_scrollbar {
  max-height: calc(90vh - 177px);
  margin-right: spacing(-4);
  padding-right: spacing(4);
  overflow-y: auto;
}

.quest_data {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: spacing(6);

  & > div {
    flex: 1 1 auto;
  }

  @include mobile-only {
    flex-direction: column;
    gap: spacing(4);
  }

  .lexend-body-m {
    font-weight: 700;
    margin-bottom: spacing(2);
  }
}

.content {
  display: flex;
  gap: spacing(8);
  flex-direction: column;

  /* The following is to hide the empty div elements */
  & > :empty {
    display: none;
  }
}
