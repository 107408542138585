.react-loading-skeleton {
  width: 100%;
  display: inline-flex;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    transform: translateX(-100%);
    animation-name: loading-skeleton;
    animation-direction: normal;
    animation-duration: normal;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

.site_dark {
  .react-loading-skeleton {
    background-color: theme(neutral, 2);
    &:after {
      background-image: linear-gradient(
        90deg,
        theme(neutral, 2),
        theme(neutral, 4),
        theme(neutral, 2)
      );
    }
  }
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}