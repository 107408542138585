$btn_transition: 0.15s ease-in-out;

.btn {
  color: theme(text-color, main) !important;
  text-decoration: none;
  font-weight: 600;
  border: none;
  cursor: pointer;

  &.xsmall {
    padding: spacing(1) spacing(2);
    font-size: 12px;
    line-height: 20px;
  }

  &.small {
    padding: spacing(2) spacing(4);
    font-size: 12px;
    line-height: 20px;
  }

  &.medium {
    padding: spacing(2.5) spacing(5);
    font-size: 12px;
    line-height: 20px;
  }

  &.large {
    padding: spacing(4) spacing(8);
  }

  &.loading {
    background-color: theme(neutral, 8);
    pointer-events: none;

    span {
      opacity: 0;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.go-back-container {
  span {
    font-weight: 700;
  }

  svg {
    margin-right: 8px;
    stroke: theme(neutral, 50);
  }
}

// RED BUTTON
.btn-red {
  background-color: theme(brand, main);
  border-radius: radius(1);
  overflow: hidden;
  font-family: var(--font-lexend);

  &:hover {
    filter: brightness(1.2);
    svg {
      stroke: theme(brand, main);
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

// BLUE BUTTON
.btn-blue {
  background-color: var(--community-color, theme(status, in-progress));
  border-radius: radius(1);
  overflow: hidden;
  font-family: var(--font-lexend);


  &:hover {
    svg {
      stroke: theme(brand, main);
    }
    filter: brightness(1.2);
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

// TRANSPARENT BUTTON
.btn-transparent {
  border-radius: radius(1);
  color: theme(neutral, 50);
  text-decoration: none;
  background: transparentize(theme(neutral, 100), 0.99);
  transition: theme(animation, duration) theme(animation, timing);
  font-family: var(--font-lexend);
  border: 1px solid transparentize(theme(neutral, 100), 0.84);

  &.red {
    &:before {
      background: linear-gradient(
        45deg,
        var(--community-color, theme(brand, main)) 0%,
        rgba(7, 7, 10, 0) 30%,
        rgba(6, 6, 9, 0) 70%,
        var(--community-color, theme(brand, main)) 100%
      );
    }
  }

  &:hover {
    filter: brightness(1.2);
  }

  &.disabled {
    opacity: 0.5;
  }
}

.btn-secondary {
  background-color: theme(neutral, 16);
  border-radius: radius(1);
}

.btn-ghost {
  background-color: transparent;
  border-radius: radius(0.5);
  padding: spacing(4) spacing(4);

  &.xsmall {
    padding: spacing(2) spacing(2);
  }
  &:hover {
    filter: brightness(1.2);
    & svg > path {
      stroke: white;
    }
  }
}

.btn-underline {
  display: inline;
  background-color: transparent;
  padding: spacing(0) !important;
  text-decoration: underline;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  span {
    display: inline;
  }
}

// OUTLINE BUTTON
.btn-outline {
  color: theme(neutral, 25);
  border: thin solid theme(neutral, 25);
  background-color: transparent;
  border-radius: radius(1);
  overflow: hidden;
  font-family: var(--font-lexend);

  &:hover {
    filter: brightness(0.9);

    svg {
      stroke: theme(brand, main);
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
