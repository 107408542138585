.quest-details__footer {

  .footer-action {
    display: flex;
    align-items: center;
    gap: spacing(4);

    .quest-rewards-container {
      display: none;
    }
    
    @include mobile-only {
      width: 100%;
      flex-direction: column;
      .final-action {
        width: 100%;
      }
      
      .footer-reward {
        display: flex;
        align-items: center;
        gap: spacing(4);

        .quest-rewards-container {
          display: block;
          padding: 0;

          .quest-reward {
            padding: 0;
            background: transparent;

            img, svg {
              width: 28px;
              height: auto;
            }

            .inventory-item-name {
              display: none;
            }
          }
        }
      }
    }
  }
  .total-participants {
    color: theme(neutral, 50);
  }
}


.final-action {
  background: var(--community-color, #{theme(brand, main)});
}