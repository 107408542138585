[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  border-radius: radius(1);
  background: theme(background, component);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  color: theme(neutral, 50);
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
}

.tippy-arrow {
  display: none;
}

.tippy-content {
  position: relative;
  padding: spacing(2);
  z-index: 1;
  font-family: var(--font-lexend);
  color: theme(text-color, main);
}
