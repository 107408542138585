.event-myrank {
  width: 50%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;

  @include mobile-only {
    flex-direction: column;
  }

  .username {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;

    @include mobile-only {
      font-size: 18px;
    }
  }

  .xp {
    font-size: 0.75rem;
    font-weight: 700;
  }

  .lexend-body-m {
    font-weight: 700;
  }

  .myrank-overview {
    position: relative;
    margin-top: spacing(2);

    .myrank-details {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .position {
        font-weight: 700;
      }

      .percentage {
        color: theme(neutral, 25);
      }
    }

    img {
      width: 211px;
      height: auto;
    }
  }


  .locked {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(12px);
    padding: spacing(3);
    font-weight: 700;
    color: theme(neutral, 50);
    text-decoration: none;
    cursor: pointer;
    transition: theme(animation, duration) theme(animation, timing);
    white-space: nowrap;

    .icon {
      margin-top: 3px;
      width: 16px;
      margin-right: spacing(2);
    }

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: radius(1);
      padding: spacing(0.25);
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(7, 7, 10, 0) 30%,
        rgba(6, 6, 9, 0) 70%,
        rgba(255, 255, 255, 0.2) 100%
      );
      mask: linear-gradient(#fff 0 0) content-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }

    svg {
      stroke: theme(neutral, 50);
    }

    &.disabled {
      cursor: disabled;
    }
  }

  @media (max-width: $md-max) {
    width: 100%;
    margin-right: 0;
    margin-bottom: spacing(6);
  }
}
