@import '@/styles/base';

.quest-pending-popup {
  border-radius: radius(1);
  width: max(100vw, 400px);
  max-width: 587px;
  padding: spacing(8);
  margin: 0 auto;

  @media (max-width: 400px) {
    max-width: 98%;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .symbol {
    margin-bottom: 16px;
  }

  .gb-loading {
    width: 64px;
    height: 64px;
  }

  .close-popup-square {
    position: absolute;
    right: spacing(8);
    top: spacing(8);
  }

  .lexend-heading-h3 {
    margin: spacing(8) 0 spacign(4);
  }

  * {
    text-align: center;
    line-height: 24px;
  }
}
