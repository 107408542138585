.loading-container {
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  flex: 1 1 auto;
  gap: spacing(2);
  width: 100%;
  flex-direction: row;
  margin-top: spacing(2);
  background-color: theme(neutral, 2);

  > span {
    display: flex;
    gap: spacing(2);
    padding: spacing(4);
  }

  .date-placeholder {
    background: linear-gradient(
      -90deg,
      theme(neutral, 12) 0%,
      theme(neutral, 8) 50%,
      theme(neutral, 12) 100%
    );
    background-size: 400% 400%;
    display: block;
    width: 100%;
    border-radius: radius(1);
    animation: pulse 1.2s ease-in-out infinite;
  }
}
