@import "@/styles/base";

.login_popup {
  transition: 0.5s ease-in;
  position: relative;
  width: 100%;
  max-width: 510px;
  max-height: 100%;
  overflow-y: auto;
  background-color: theme(neutral, 8);
  padding: spacing(6);
  display: flex;
    flex-direction: column;
    gap: spacing(8);

  @include mobile-only {
    max-width: 100%;
    
    
    height: 100dvh;
  }
}

.footnote {
  color: theme(neutral, 25);
  font-weight: 500;

/* Lexend/Body/Light/S */
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 20px;

  a,
  strong {
    color: theme(brand, main);
    text-decoration: none;
  }
}

.popup_title {
  color: theme(text-color, main);

  /* Lexend/Headings/H2 */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}

.popup_subtitle {
  color: theme(neutral, 50);

  /* Lexend/Body/Light/M */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.upper_part {
  display: flex;
  flex-direction: column;
  gap: spacing(8);
}

.title_section {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
}
.login_methods {
  display: flex;
  gap: spacing(4);
  flex-direction: column;
}

.login_method {
  font-size: 14px;
}