@import '@/styles/base';

.maintainance-page {
  overflow: hidden;
  height: 100%;
  width: 100%;

  main {
    overflow: hidden;
  }

  .video-bg {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.3;
  }

  .video-hero {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  .container.flex.between {
    min-height: 100vh;
    padding-top: spacing(12);
    padding-bottom: spacing(20);
    position: relative;
    z-index: 2;
  }

  .coming-soon {
    font-size: 80px;
    font-weight: 600;
    line-height: 80px; /* 100% */
    font-family: var(--font-integral-cf);
    max-width: 574px;
  }

  .cyberpunk-citizen {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    height: 100%;
    width: auto;
  }

  .footer {
    gap: spacing(4);

    .external-link {
      border-radius: radius(1);
      background: rgba(255, 255, 255, 0.06);
      backdrop-filter: blur(16px);
      padding: spacing(3) spacing(4);
      color: #fff;
      text-decoration: none;
      gap: spacing(2);
      transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);

      &:hover {
        background: rgba(255, 255, 255, 0.12);
      }

      svg {
        fill: #fff;
      }
    }
  }

  @media (max-width: $md-max) {
    .cyberpunk-citizen {
      display: none;
    }

    .coming-soon {
      font-size: 64px;
      line-height: 64px; /* 100% */
    }
  }

  @media (max-width: $sm-max) {
    .coming-soon {
      font-size: 48px;
      line-height: 48px; /* 100% */
    }
  }

  @media (max-width: $xxs-max) {
    .coming-soon {
      font-size: 32px;
      line-height: 32px; /* 100% */
    }
  }
}
