.quest-requirements.poll,
.quest-requirements.quiz {
  .quiz-options {
    position: relative;
  }

  .alert-simple {
    margin-top: spacing(2);
  }

  .dimmed {
    opacity: 0.5;
  }

  .btn-transparent.selected {
    background-color: rgba(255, 255, 255, 0.08);
  }

  .btn-fill {
    position: absolute;
    left: 0;
    z-index: 0;
    height: 100%;
    border-radius: 8px;
    width: 0;
    transition: width 0.5s ease-in;
    transition-delay: 0.3s;
  }

  .btn-fill.winner {
    background-color: theme(brand, main);
  }

  .btn-fill.loser {
    background-color: theme(status, in-progress);
  }
}
