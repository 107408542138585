.landing-community-stats {
  .lexend-body-s {
    font-weight: 700;
  }

  .lexend-body-m {
    margin: 0 16px 0 8px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tags-list {
    white-space: nowrap;

    .single-tag {
      margin-right: spacing(2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .community-row {
    margin-top: spacing(2);
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.04);
    padding: spacing(3);
    color: theme(neutral, 50);
    text-decoration: none;

    .single-tag {
      pointer-events: none;
    }

    .community-logo {
      border-radius: 50%;
    }

    & .lexend-body-m {
      margin: 0;
    }
  }

  .explore-button {
    padding: spacing(4);

    &:before {
      background: linear-gradient(
        45deg,
        theme(brand, main) 0%,
        rgba(7, 7, 10, 0) 30%,
        rgba(6, 6, 9, 0) 70%,
        theme(brand, main) 100%
      );
    }
  }
}

.empty-community {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  gap: spacing(4);
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: radius(1);
  padding: spacing(4);

  &__button {
    width: 100%;
    font-size: 16px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
  }
  &__heading {
    font-weight: 500;
    font-family: var(--font-lexend);
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: theme(neutral, 25);
  }
}
