nav.navigation {
  position: fixed;
  top: 0px;
  width: 100%;
  padding: spacing(8) spacing(8) 0 spacing(8);
  z-index: 3;
  transition: theme(animation, duration) theme(animation, timing);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    backdrop-filter: blur(8px);
    -webkit-mask: linear-gradient(0deg, transparent, black 50%);
  }

  .brand--hidden {
    visibility: hidden;
  }
  .beta {
    font-size: 12px;
    margin-left: spacing(4);
    pointer-events: none;
  }

  &.user {
    background-color: theme(background, app);
  }

  .login-button {
    font-weight: 700;
    margin-right: spacing(4);
  }

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  @media (max-width: $md-max) {
    padding: spacing(4) spacing(5.5);
    // background-color: theme(neutral, 2);

    .beta {
      display: none;
    }
  }
}