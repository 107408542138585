@import '@/styles/base';

.levelup-popup {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.1;
  }

  .actions {
    position: fixed;
    bottom: 64px;
    right: 64px;
  }

  .level {
    margin-bottom: spacing(4);
    position: relative;
  }

  .level-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 90px;
  }

  .video {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
    width: 250%;
    z-index: -1;
  }
}

.page-popups-container-level-up {
  max-height: 100%;
  overflow: hidden;
  background-color: #0d0d12;

  .page-popup {
    overflow: initial;
    position: initial;
  }
}
