@import "@/styles/base";
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  display: flex; 
  flex-direction: column;
  gap: spacing(0.5);
}

.tag_title {
  color: theme(text-color, main);

/* Lexend/Body/Bold/S */
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */

display: flex;
align-items: center;
gap: spacing(2);
}

.title {
  color: theme(text-color, main);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}