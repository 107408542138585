@import '@/styles/base';

.modal_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.username_claimed_popup {
  width: 854px;
  gap: spacing(8);

  @media (max-width: $md-max) {
    width: 100%;
  }
}

.title {
  font-weight: 600;
}

.footnote {
  color: theme(neutral, 50);
  transform: scale(0.7) translateY(-100%);
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  margin: 0;
}

.claimed_username {
  font-size: 48px;
  line-height: 1;
  font-family: var(--font-integral-cf);
  padding: spacing(14);
  position: relative;
  z-index: 2000000;
  border-radius: radius(4);
  background: theme(neutral, 4);
}

.claimed_username_container {
  position: relative;
  overflow: hidden;
  padding: 1px;
  border-radius: radius(4);

  &:before {
    content: '';
    position: absolute;
    width: 110%;
    height: 910px;
    transition: theme(animation, duration) theme(animation, timing);
    border-radius: 8px;
    background: linear-gradient(
      45deg,
      rgba(235, 58, 74, 1) 0%,
      rgba(7, 7, 10, 0) 40%,
      rgba(6, 6, 9, 0) 50%,
      rgba(235, 58, 74, 1) 100%
    );
    z-index: -1124123123;
    pointer-events: none;
    animation: orbit 6s ease-in infinite;
  }
}


@keyframes orbit {
  to {
    transform: rotate(360deg);
  }
}

