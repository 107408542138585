.input-wrapper {
  position: relative;

  .input-container {
    position: relative;
  }

  .validation-container {
    padding-top: spacing(2);
    background-color: theme(neutral, 4);
    display: flex;
    justify-content: space-between;
  }

  textarea {
    background-color: transparent;
    border: 1px solid theme(neutral, 4);
    padding: spacing(3);
    font-weight: 500;
    font-size: 16px;
    font-family: var(--font-lexend);
    color: theme(neutral, 50);
    resize: vertical;
    min-height: 116px;
  }

  ::placeholder {
    color: theme(neutral, 25);
    opacity: 1;
  }

  .count-characters {
    margin-top: spacing(2);
    color: theme(neutral, 50);
    text-align: right;

    &.reached {
      color: theme(status, error);
    }
  }

  &.has-error {
    textarea {
      border-color: theme(status, error);
    }
    .input-error {
      color: theme(status, error);
    }
  }
}
