.quest-stats__list {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: spacing(2);
  height: 100%;
}

.quest-stat {
  flex: 1;
  border-radius: radius(1);
  background: rgba(255, 255, 255, 0.04);
  padding: spacing(2);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: spacing(2);
  font-weight: 700;

  .lexend-heading-h2 {
    text-align: center;
  }
  .lexend-body-xs1 {
    color: theme(neutral, 50);
    text-align: center;
    text-wrap: balance;
  }
}
