.quests-categorized-list {
  display: flex;
  flex-direction: column;
  gap: spacing(6);

  .categories-list {
    margin-top: spacing(2);
  }

  & .list-error {
    margin-top: spacing(4);
    padding: spacing(8);
  }

  .category-container {
    display: flex;
    flex-direction: column;
    gap: spacing(6);
  }
  .category-name {
    font-weight: 700;
  }

  .category-quests {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    grid-auto-rows: 1fr;
    gap: spacing(6);
    align-content: stretch;

    @include large-desktop-up {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .quest-container {
    width: 50%;
    padding: spacing(3) spacing(1.5);
  }

  @media (max-width: $lg-max) {
    .quest-container {
      width: 100%;
    }
  }

  @media (max-width: $md-max) {
    .quest-container {
      width: 50%;
    }
  }

  @media (max-width: $sm-max) {
    .quest-container {
      width: 100%;
    }
  }
}
