.page-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    border: 1px solid #202020;
    cursor: pointer;
    color: theme(neutral, 50);
    transition: 0.2s ease-in;
    border-radius: radius(1);

    &:hover {
      border-color: theme(brand, main);
      color: theme(neutral, 50);
    }
  }

  .selected {
    a {
      color: theme(text-color, main);
      border-color: theme(brand, main);
      background-color: theme(brand, main);
    }
    a:hover {
      background-color: darken(theme(brand, main), 0.2);
    }
  }

  .next,
  .previous {
    a {
      border-color: theme(neutral, 4);
      background-color: theme(neutral, 4);
    }
  }
}

.site_light {
  .page-pagination {
    .selected {
      color: theme(neutral, 50);
    }

    a {
      border-color: theme(neutral, 50);
    }

    .next,
    .previous {
      a {
        border-color: theme(neutral, 50);
        background-color: theme(neutral, 50);
      }
    }
  }
}
