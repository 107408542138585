@import '@/styles/base';

.acquisition-rules {
  @include mobile-only {
    height: 100svh;
  }
  
  .description {
    color: #868688;
    overflow: hidden;
    text-align: center;
  }
  
}
