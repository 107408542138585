.leaderboard-list {
  .leaderboard-list-head {
    color: theme(neutral, 100);
    font-weight: 700;
    font-size: 14px;
    margin-bottom: spacing(2);

    img {
      margin-right: spacing(1);
    }
  }

  .leaderboard-current-rank {
    margin-bottom: spacing(4);
    padding: spacing(3);
    border-radius: radius(1.5);
    background-color: theme(neutral, 4);
    font-weight: 700;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
    }

    .exp-image {
      margin: 0 9px 0 12px;
    }

    .current-rank-name {
      margin-left: spacing(2);
    }

    &.rank-1 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.02) 0%,
          rgba(255, 255, 255, 0.02) 100%
        ),
        linear-gradient(
          90deg,
          rgba(202, 178, 98, 0.2) 0%,
          rgba(202, 178, 98, 0) 51.34%
        );

      &:after {
        background-color: theme(leaderboard, 1st);
      }
    }

    &.rank-2 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.02) 0%,
          rgba(255, 255, 255, 0.02) 100%
        ),
        linear-gradient(
          90deg,
          rgba(251, 251, 251, 0.2) 0%,
          rgba(202, 178, 98, 0) 51.34%
        );

      &:after {
        background-color: theme(leaderboard, 2nd);
      }
    }

    &.rank-3 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.02) 0%,
          rgba(255, 255, 255, 0.02) 100%
        ),
        linear-gradient(
          90deg,
          rgba(134, 88, 64, 0.2) 0%,
          rgba(134, 88, 64, 0) 52.97%
        );
      &:after {
        background-color: theme(leaderboard, 3rd);
      }
    }
  }

  .list {
    background-color: theme(neutral, 4);
    border-radius: radius(1);
  }

  .index {
    width: 32px;
    height: 32px;
    font-weight: 700;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }

  .index-1 {
    background: url('/images/leaderboard/gold.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
      display: none;
    }
  }

  .index-2 {
    background: url('/images/leaderboard/silver.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    svg {
      display: none;
    }

    &:before {
      display: none;
    }
  }

  .index-3 {
    background: url('/images/leaderboard/bronze.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    svg {
      display: none;
    }

    &:before {
      display: none;
    }
  }
}
