@import "@/styles/base";

.battlenet_extended_socials {
    display: flex;
    flex-direction: column;
    gap: spacing(4);
    margin-block: spacing(4);
}

.battlenet_character_info {
    display: flex;
    flex-direction: row;
    gap: spacing(2);
}

.battlenet_character_name {
    color: theme(text-color, main);
    font-family: var(--font-lexend);
    font-size: 0.75rem;
}
.battlenet_character_realm {
    color: theme(neutral, 25);
}