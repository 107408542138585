@import "@/styles/base";

.list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(2);
    
    a {
        width: 40px;
        height: 40px;
        padding: spacing(1);
        transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        position: relative;

        img {
            border-radius: radius(0.5);
            width: 100%;
            height: auto;
            transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

}


.explore {
    border-radius: radius(0.5);

    svg {
        stroke: theme(neutral, 25);
        transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
        background-color: transparent;

        svg {
            stroke: theme(neutral, 50);
        }
    }
    &.active {
        svg {
            stroke: theme(text-color, main);
        }
    }
}

.icon_container {
    stroke: theme(neutral, 25); 

    &:hover {
        stroke: theme(neutral, 50);
    }
}

.active .icon_container {
    stroke: theme(text-color, main);
}