.quest-objective {
  background: rgba(255, 255, 255, 0.04);
  padding: spacing(4);
  border-radius: radius(1);
  position: relative;
  overflow: hidden;

  @include mobile-only {
    flex-direction: column;
    gap: spacing(4);
    > button {
      width: 100%;
    }
  }

  &.completed {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: theme(status, success);
    }
  }

  .lexend-body-l {
    font-weight: 700;
  }
}
