@import "@/styles/base";

.community-referral {
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: radius(1);
  padding: spacing(6);
  position: relative;

  &__logo-container {
    align-self: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1/1;
  }
  &__close {
    position: absolute;
    top: spacing(6);
    right: spacing(6);
    stroke: white;
    aspect-ratio: 1/1;
    width: 32px;
  }
  &__title {
    font-weight: 700;
    font-family: var(--font-lexend);
    font-size: 32px;
    line-height: 40px;
    color: white;
    text-align: center;
  }
}
