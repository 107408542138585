.gamer-card {
  position: relative;
  grid-area: avatar;
  aspect-ratio: 534 / 806;
  isolation: isolate;
  padding: spacing(1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__overlay {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: stretch;
    z-index: 9;

    &::after {
      background: radial-gradient(transparent, black);
      position: absolute;
      content: '';
      inset: 0;
      z-index: -1;
    }
  }
  &__forge-bar {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(6) !important;
    margin: 0 spacing(2);
    color: theme(text-color, main);
    stroke: theme(text-color, main);
    z-index: 9;
  }

  &__bg {
    object-fit: cover;
    object-position: top;
    z-index: -3;
    mask-origin: padding-box;

    &--border {
      z-index: 2;
      user-select: none;
      pointer-events: none;
    }
  }
  &__fg {
    object-fit: cover;
    object-position: top;
    z-index: -2;
    mask-origin: padding-box;
  }
  &__inventory {
    grid-area: inventory;
    display: flex;
    flex-direction: column;
    gap: spacing(8);
    align-items: center;
    padding: 0 0 0 spacing(6);

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: spacing(4);
      max-width: 420px;
    }
    &-heading {
      font-family: var(--font-lexend);
      font-size: 2rem;
      line-height: 100%;
      color: theme(text-color, main);
      font-weight: 600;
      text-transform: uppercase;
    }
    &-coming-soon {
      text-align: center;
      font-family: var(--font-lexend);
      border-radius: 16px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 150%; /* 150% */
    }
    &-body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
      gap: spacing(4);
      grid-auto-rows: 1fr;
      width: 100%;
      justify-items: center;
    }
  }
  &__logo {
    --logo-offset: 2rem;
    position: absolute;
    top: var(--logo-offset);
    left: var(--logo-offset);
  }
  &__host-logo {
    --logo-offset: 2rem;
    filter: grayscale(100%) contrast(100%) brightness(200%);
    background-color: theme(neutral, 2);
    border-radius: 9999px;
    padding: 10px;
    position: absolute;
    top: var(--logo-offset);
    right: var(--logo-offset);
  }
}

.gamer-card-shrapnel {
  aspect-ratio: 608/816 !important;

  .gamer-card__host-logo {
    width: 32px;
    height: 32px;
  }

  .gamer-card__logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
