.Toastify__toast {
  font-weight: 700;
  line-height: 21px;
  border-radius: radius(1);
  font-size: 12px;
  color: theme(neutral, 50);
  background-color: theme(neutral, 4);
  font-family: var(--font-lexend);

  .Toastify__close-button {
    margin-top: 5px;
    margin-right: 3px;
  }

  span {
    color: theme(neutral, 50);
  }

  .Toastify__progress-bar {
    background-color: theme(brand, main);
    height: 2px;
  }
}
