@function spacing($factor: 1) {
  @return theme(sizes, base-space) * $factor;
}

@function radius($factor: 1) {
  @return theme(sizes, base-radius) * $factor;
}

// Define a mixin to generate gap classes
// TODO: deprecate this
@mixin gap-generator($sizes) {
  @each $size, $factor in $sizes {
    .gap-#{$size} {
      gap: spacing($factor);
    }
  }
}
