@import "@/styles/base";

.deleteaccount-popup {
  max-width: 420px;

  .popup-title {
    margin-bottom: spacing(6);
    color: white
  }

  .row {
    color: theme(neutral, 50);

    &:first-child {
      margin-bottom: spacing(8);
    }
  }

  .cancel {
    margin-right: spacing(2);
  }

  .verify {
    margin: spacing(8) 0 spacing(8) spacing(2);
  }

  .input-wrapper {
    margin: spacing(4) 0 spacing(6);
  }
}
