.loading-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid #fff;
  }

  &.big {
    width: 48px;
    height: 48px;

    &:before {
      border-width: 5px;
    }
  }

  &.xsmall {
    width: 16px;
    height: 16px;

    &:before {
      border-width: 2px;
    }
  }
}

