.select-region-trigger {
  background-color: transparent;
  border: none;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;

  .stroke {
    stroke: #fff;
    margin-left: spacing(1);
    transform: rotate(-90deg);
    transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.active {
  .select-region-trigger {
    .stroke {
      transform: rotate(90deg);
    }
  }
}
