@import "@/styles/base";

.equip_item_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing(4);
}
.close_button {
  position: absolute;
  top: spacing(4);
  right: spacing(4);
  padding: 0 !important;
  width: 32px;
  height: 32px;

  @include mobile-only {
    width: 48px;
    height: 48px;
    border-radius: radius(2);
  }

  & svg {
    stroke: white;
  }
}
.item_name {
  color: var(--item-rarity);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.item_name_legendary {
  --item-rarity: #{theme(rarity, legendary)};
}
.item_name_rare {
  --item-rarity: #{theme(rarity, rare)};
}
.item_name_common {
  --item-rarity: #{theme(rarity, common)};
}
.item_name_epic {
  --item-rarity: #{theme(rarity, epic)};
}

.image_container_locked {
  opacity: 0.6;
  filter: brightness(0.7);
  z-index: -1;
}
.lock {
  aspect-ratio: 1/1;
  width: 2.5rem;
  pointer-events: none;

  & svg {
    stroke: white;
  }
}
.equip_popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: spacing(8);
  position: relative;
  align-items: center;
  height: 100%;
}

.cta {
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  @include mobile-only {
    position: relative;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: auto;
  }
}

.item_image {
  max-width: 100%;
  object-fit: contain;
}
.item_description {
  color: theme(text-color, main);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}
