@import '@/styles/base';

.glass-card {
  padding: spacing(3);
  background: linear-gradient(
    225deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(12px);
  font-weight: 500;
  transition: theme(animation, duration) theme(animation, timing);
}


.variant_component {
  background-color: theme(background, component);
}
.variant_overlay {
  background-color: theme(background, overlay);
  padding: spacing(3); 
}
.variant_outlined {
  background-color: transparent;
  border: 1px solid theme(neutral, 8);
  padding: spacing(3);
}

.spacing_regular {
  padding: spacing(6);
  @include mobile-only {
    padding: spacing(3);
  }
}

.spacing_narrow {
  padding: spacing(4);
  @include mobile-only {
    padding: spacing(2);
  }
}

.spacing_none {
  padding: 0;
}

.spacing_tight {
  padding: spacing(3);
  @include mobile-only {
    padding: spacing(1.5);
  }
}

.spacing_ample {
  padding: spacing(8);
  @include mobile-only {
    padding: spacing(4);
  }
}

.roundness_regular {
  border-radius: radius(2);
}
.roundness_tight {
  border-radius: radius(1);
}
.roundness_none {
  border-radius: 0;
}