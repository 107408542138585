.single-tag {
  padding: spacing(1) spacing(2);
  text-decoration: none;
  color: theme(neutral, 16);
  font-weight: 700;
  border-radius: radius(1);
  transition: 0.2s ease-in;
  position: relative;
  backdrop-filter: blur(8px);
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: radius(1);
    padding: spacing(0.25);
    background: linear-gradient(
      54deg,
      transparent 85%,
      rgba(255, 255, 255, 0.2) 100%
    );
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    opacity: 0;
    transition: theme(animation, duration) theme(animation, timing);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    color: theme(neutral, 50);
  }

  .suffix {
    margin-left: spacing(1);
  }

  .top {
    position: absolute;
    left: 90%;
    top: -10px;
    background-color: theme(brand, main);
    color: theme(neutral, 50);
    padding: spacing(1);
    font-size: 8px;
    line-height: 8px;
  }

  &.active {
    color: theme(neutral, 50);
    background: rgba(255, 255, 255, 0.08);

    &:before {
      opacity: 1;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

// Rarity colors
.common {
  .single-tag.active {
    background-color: theme(neutral, 12);
    color: theme(text-color, main);
  }
}
.rare {
  .single-tag.active {
    background-color: theme(rarity, rare);
    color: theme(background, app);
  }
}
.epic {
  .single-tag.active {
    color: theme(background, app);
    background-color: theme(rarity, epic);
  }
}
.legendary {
  .single-tag.active {
    color: theme(background, app);
    background-color: theme(rarity, legendary);
  }
}
