.community-sidebar {
  border-top-right-radius: radius(1);
  border-bottom-right-radius: radius(1);
  background-color: theme(background, component);
  left: -200px;
  transition: all .3s ease;
  transition-delay: 0.2s;
  position: fixed;
  top: 56px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 1;
  

  @include desktop-up {
    grid-area: sidebar;
    margin: 2px 0 0 2px;
    z-index: 3;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    left: calc(var(--sidebar-width) + 2px);
    width: 172px;
    height: calc(100% - 4px);
    top: 0;
  }

  &--mobile-visible {
    left: calc(var(--sidebar-width) + 2px);
    z-index: 99;
  }

  &__footer {
    padding: spacing(4);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(2);
    border-radius: radius(1);
    overflow: hidden;

    &-username {
      text-align: center;
      font-weight: 700;
    }

    &__rank {
      position: relative;
      padding: spacing(3);
      border-radius: radius(1.5);
      background: theme(neutral, 8);
      backdrop-filter: blur(12px);
      width: 100%;
      font-weight: 500;

      &.avatar-visible {
        padding-left: spacing(6);
      }

      .lexend-body-xxs {
        opacity: 0.5;
      }

      .level {
        opacity: 0.5;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        line-height: 10px;
      }
    }

    &-level {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: spacing(1);

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        max-width: 7rem;
        line-height: 14px;
        font-size: 12px;
      }
    }
    &-avatar-thumbnail {
      position: relative;
      display: flex;
      aspect-ratio: 1/1;
      width: 104px;
      background-color: #0d0d12;
      border-radius: radius(2);
      overflow: clip;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      isolation: isolate;
      background: linear-gradient(
        20deg,
        rgb(0, 0, 0) 0%,
        var(--community-color, theme(neutral, 50)) 0%,
        #0d0d12 40%,
        rgba(0, 0, 0, 0.4) 100%
      );
      overflow: hidden;
      background-repeat: no-repeat;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: radius(2);
        padding: calc(theme(sizes, base-space) * 0.25);
        background: linear-gradient(
          310deg,
          rgba(255, 255, 255, 0.2) 0%,
          transparent 40%,
          transparent 60%,
          rgba(255, 255, 255, 0.2) 100%
        );
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }

      & img {
        z-index: -1;
        pointer-events: none;
        object-fit: cover;
        object-position: top;
        transform: scale(2.5) translateY(24px);
      }
      & button {
        backdrop-filter: blur(16px);
      }
    }
  }
}
