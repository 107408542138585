@import '@/styles/base';

.button_svg_fill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: radius(1);

    svg {
        fill: theme(neutral, 50);
    }

    &:hover {
        svg {
            fill: theme(text-color, main);
        }
        background-color: theme(neutral, 8);
    }
}

.button_svg_stroke {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: radius(1);

    svg {
        stroke: theme(neutral, 50);
    }

    &:hover {
        svg {
            stroke: theme(text-color, main);
        }
        background-color: theme(neutral, 8);
    }
}


.button_svg_fill.active {
    background-color: theme(neutral, 8);
    border-radius: radius(1);
    padding-block: spacing(1);
    padding-inline: spacing(1);

    svg {
        fill: theme(text-color, main);
    }
}

.button_svg_stroke.active {
    background-color: theme(neutral, 8);
    border-radius: radius(1);
    padding-block: spacing(1);
    padding-inline: spacing(1);
    
    svg {
        stroke: theme(text-color, main);
    }
}