.file-select-wrapper {
  .file-select {
    border-radius: radius(1);
    border: 1px dashed rgba(255, 255, 255, 0.16);
    background: rgba(255, 255, 255, 0.04);
    padding: spacing(8);
    color: theme(neutral, 50);
    cursor: pointer;

    svg {
      stroke: theme(neutral, 100) !important;
    }
  }
  
  .thumb-container {
    max-width: 33.3333%;
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.04);
    position: relative;
    padding: spacing(4);


    .thumb {
      border-radius: radius(1);
      overflow: hidden;
      margin-right: spacing(4);

      img {
        position: relative !important;
        width: 48px !important;
        height: 48px !important;
      }
    }

    .image-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 80px;
      font-weight: 500;
    }
  }

  .delete-file {
    opacity: 0.5;
    position: relative;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:after {
      content: '';
      position: absolute;
      left: -8px;
      top: -8px;
      bottom: -8px;
      right: -8px;
    }
  }
}
