.event-progress {
  .cube-container {
    padding: 0;
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-template-rows: 16px 1fr;
    align-items: center;
    justify-items: center;

    @include mobile-only {
      align-items: start;
      column-gap: spacing(2);
    }

    .cube {
      width: 12px;
      height: 12px;
      background-color: transparentize(theme(neutral, 100), 0.96);
      border-radius: 50%;

      @media screen and (max-width: $sm-max) {
        margin-top: spacing(2);
      }
    }

    &.active .cube {
      background-color: theme(status, success);
    }

    .status-detail-block {
      white-space: nowrap;
      grid-column: 1 / span 2;
      justify-self: start;

      @media screen and (max-width: $sm-max) {
        grid-column: 2;
        grid-row: 1 / span 2;
        width: 100%;

        .lexend-body-s {
          margin-top: 0;
        }
      }

      span {
        width: 0px;
      }

      &.active {
        .lexend-body-s, .lexend-body-xs1 {
          color: white;

          &.active {
            color: theme(status, success)
          }
        }
      }
    }
  }

  .cubes {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(130px, 30%) 1fr minmax(130px, 5%);
    grid-template-rows: 1fr;
    column-gap: spacing(2);

    @include mobile-only {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: 1fr;
    }
  }

  .progress-bar {
    @include mobile-only {
      display: none;
    }
  }

  .mobile-progress-bar {
    display: none;
    @include mobile-only {
      display: block;
      position: relative;
      width: 1px;
      background: theme(neutral, 16);
      height: 3rem;

      div {
        position: absolute;
        top:0;
        left: 0;
        width: 1px;
        background: theme(status, success);
      }
    }
  }

  .lexend-body-s {
    font-weight: 700;
    margin-top: spacing(2);
    color: transparentize(theme(neutral, 100), 0.75);
  }

  .lexend-body-xs1 {
    color: transparentize(theme(neutral, 100), 0.75) ;

    span.date-placeholder {
      background-color: theme(neutral, 2);
      width: 80px;
    }
  }
}
