.react-code-input {
  display: flex !important;

  input {
    width: 56px;
    height: 56px;
    background-color: theme(neutral, 2);
    border: 1px solid theme(neutral, 2);
    transition: theme(animation, duration) theme(animation, timing);
    text-align: center;
    font-family: var(--font-lexend);
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;

    &:active,
    &:focus,
    &:hover {
      background-color: theme(neutral, 4);
      border-color: theme(brand, main);
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
