.referral-input {
  position: relative;

  &-wrapper {
    position: relative;
  }

  input {
    border: none;
    color: theme(neutral, 50);
    padding: spacing(3.5) spacing(14) spacing(3.5) spacing(4);
    font-weight: 500;
    font-family: var(--font-lexend);
    border-radius: radius(1);
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(4px);
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .btn-copy {
    width: 56px;
    aspect-ratio: 1/1;
    position: absolute;
    right: 0;
    top: auto;
    font-weight: 500;
    transition: theme(animation, duration) theme(animation, timing);
    cursor: pointer;
    overflow: hidden;
    border-radius: 0;
    border-top-right-radius: radius(1);
    border-bottom-right-radius: radius(1);
  }
}
