.dropdown-select {
  position: relative;
  width: 100%;

  .dropdown-select-btn {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: spacing(3);
    border-radius: radius(1.5);
    border: 1px solid rgba(255, 255, 255, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      border-color: rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
  .dropdown-select-arrow {
    rotate: -90deg;
  }

  .dropdown-select-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(20px);
    border-radius: radius(1);
    background: theme(neutral, 8);
    
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    max-height: 300px;
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    padding: 4px;

    &.anchor-top-right {
      right: 0;
      left: auto;
    }

    .simplebar-track.simplebar-vertical {
      right: 2px;
      top: spacing(2);
      bottom: spacing(2);
    }

    a {
      color: theme(neutral, 50);
      padding: spacing(2);
      text-decoration: none;
      transition: 0.2s ease-in;

      &:hover {
        border-radius: radius(1);
        background: rgba(255, 255, 255, 0.04);
      }
    }

    .simplebar-scrollable-y {
      max-height: 300px;
      overflow: hidden;
    }

    .simplebar-content {
      display: flex;
      flex-direction: column;
    }
  }

  &.active {
    .dropdown-select-list {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0px);
      width: 100%;
      transform-origin: top left;
    }
    .dropdown-select-arrow {
      rotate: -270deg;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
