.quest-status {
  display: inline-flex;
  flex-shrink: 0;

  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border-width: 2px;
  }

  &.active,
  &.resolving {
    .circle {
      background-color: theme(status, warning);
    }

    span {
      color: theme(neutral, 50);
    }
  }

  &.completed {
    .circle {
      background-color: theme(rarity, rare);
    }

    span {
      color: theme(neutral, 50);
    }
  }
}

.site_light {
  .quest-status.active span,
  .quest-status.resolving span {
    color: theme(neutral, 2);
  }

  .quest-status.completed span {
    color: theme(neutral, 2);
  }
}
