.swiper {
  .swiper-pagination {
    z-index: 5;
  }

  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.25);
    width: 8px;
    height: 8px;
    opacity: 1;
    margin: 0 4px;
    border-radius: radius(1);
    transition: 0.2s ease-in;

    &-active {
      background-color: theme(brand, main);
      width: 24px;
    }
  }

  &.pagination-top {
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: auto;
      left: auto;
      width: auto;
      top: 0;
      right: -4px;
    }
  }
}
