.quest-requirements.text-submission {
  @include tablet-up {
    .quest-data {
      width: 50%;
    }
  }
  .quest-requirement {
    padding: 0;
  }
  textarea {
    color: white
  }
}
