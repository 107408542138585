@import '@/styles/base';
.leave-community-popup {
  width: 250px;

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: spacing(8);
  }

  .actions {
    .btn-red {
      white-space: nowrap;

      &:first-child {
        margin-right: spacing(2);
      }
      &:last-child {
        margin-left: spacing(2);
      }
    }
  }
}
