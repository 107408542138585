.landing__footer {
  background-color: #131319;
  padding-top: spacing(4);
  padding-bottom: spacing(8);

  .links {
    a {
      color: #868688;
      padding: spacing(4) spacing(4);
      text-decoration: none;
      transition: theme(animation, duration) theme(animation, timing);

      &:hover {
        color: theme(brand, main);
      }
    }
  }

  .disclaimer {
    margin-top: spacing(4);
    color: #868688;
    font-weight: 300;
  }

  @media (max-width: $sm-max) {
    padding-top: spacing(10);
    padding-bottom: spacing(8);

    .content {
      flex-direction: column;
      align-items: flex-start;
    }

    .links {
      flex-wrap: wrap;
      width: 100%;

      a {
        flex: 0 0 50%;
        padding: spacing(3) 0;
      }
    }

    .footer__logo {
      width: spacing(10);
      height: spacing(10);
      margin-bottom: spacing(2);
    }
  }
}
