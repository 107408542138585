@import '@/styles/base';

.whitelist-form {
  margin-top: spacing(2);
  position: relative;

  input {
    height: 56px;
  }

  & .input-wrapper {
    margin-top: 0;
  }

  .btn-transparent {
    position: absolute;
    right: spacing(1);
    top: spacing(1);
  }
}
