.leaderboard-list-row {
  margin-bottom: spacing(1);
  padding: spacing(1) spacing(2);
  transition: 0.2s ease-in;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
  }

  .index {
    margin-right: spacing(8);

    @include mobile-only {
      margin-inline-end: spacing(2);
    }
  }

  &.list-row-1 {
    border-radius: radius(1);
    overflow: hidden;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.02) 0%,
        rgba(255, 255, 255, 0.02) 100%
      ),
      linear-gradient(
        90deg,
        rgba(202, 178, 98, 0.2) 0%,
        rgba(202, 178, 98, 0) 100%
      );

    &:after {
      background-color: theme(leaderboard, 1st);
    }
  }

  &.list-row-2 {
    border-radius: radius(1);
    overflow: hidden;
    background: linear-gradient(
      90deg,
      rgba(251, 251, 251, 0.2) 0%,
      rgba(251, 251, 251, 0) 100%
    );

    &:after {
      background-color: #fbfbfb;
    }
  }

  &.list-row-3 {
    border-radius: radius(1);
    overflow: hidden;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.02) 0%,
        rgba(255, 255, 255, 0.02) 100%
      ),
      linear-gradient(
        90deg,
        rgba(134, 88, 64, 0.2) 0%,
        rgba(134, 88, 64, 0) 100%
      );

    &:after {
      background-color: #865840;
    }
  }

  .username {
    font-weight: 600;
    text-overflow: ellipsis;
  }

  .points {
    font-weight: 600;

    img {
      width: 13.74px;
      height: 32px;
      margin-right: spacing(2);
    }
  }
}
