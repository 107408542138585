.google-btn {
  position: relative;
  height: 56px;

  div:first-child {
    margin: 0 2px;
    opacity: 0.01;
  }

  svg {
    stroke: none !important;
  }

  .loading-spinner {
    opacity: 1 !important;
  }
  .btn-transparent {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    pointer-events: none;

    .lexend-body-s {
      font-family: var(--font-lexend);
      font-weight: 500;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
