@import "@/styles/base";

.user_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(4);
}

.account {
    svg {
        stroke: theme(neutral, 50);
    }

    &:hover {
        svg: {
            stroke: theme(text-color, main);
        }
    }
}

.social_links_and_logout {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
}

.social_links {
    display: flex;
    justify-content: space-evenly;
    margin-top: spacing(2);

    @include mobile-only {
        gap: spacing(3);
        justify-content: flex-start;
    }

    & > a {
        background-color: theme(neutral , 8);
    }
}

.menu_container {
    position: absolute;
    bottom: 2rem;
    left: calc(.5rem + var(--sidebar-width));
}
.sidebar-menu-links {
    border-radius: radius(1);
    background-color: theme(neutral, 8);
    padding: spacing(2);
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    z-index: 999;
    width: 162px;

    & a:not(.danger), & a:not(.danger):visited {
        display: flex;
        color: theme(text-color, main);
        text-decoration: none;
        font-weight: bold;
        line-height: 20px;
        font-size: 12px;
        padding: spacing(2);
        border-radius : radius(1);
        transition: all .3s ease;
    }
    & a:not(.danger):hover {
        background-color: theme(neutral, 16);   
        
    }

    @include mobile-only {
        --navigation-height: 56px;
        position: fixed;
        width: calc(100vw - var(--sidebar-width));
        top: var(--navigation-height);
        bottom: 0;
        right: 0;
        left: calc(var(--sidebar-width));
        background-color: transparentize(theme(neutral, 4), 0.1);
        backdrop-filter: blur(16px);
        z-index: 99999;
        padding: spacing(4);

        justify-content: space-between;
    }

}

.danger {
    color: theme(status, error);
    text-decoration: none;
    font-weight: bold;
    line-height: 20px;
    font-size: 12px;
    padding: spacing(2);
    display: flex;
    border-radius : radius(1);
    transition: all .3s ease;

    &:hover {
        color: lighten(theme(status, error), 10%);
        background-color: rgba(lighten(theme(status, error), 20%), 0.1)
    }
}
