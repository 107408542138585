.parser-content {
  .tag-ol {
    list-style-type: decimal-leading-zero;
    list-style-position: inside;
    margin: 8px 0;
    padding-left: spacing(2);
  }

  .tag-ul {
    list-style-type: circle;
    list-style-position: inside;
  }
  .tag-p {
    margin-top: spacing(2);
    font-weight: normal;
    font-size: small;
  }
  .text-bold {
    font-weight: 700;
  }

  .tag-i {
    font-style: italic;
  }

  .tag-strong {
    font-weight: bold;
  }
  .tag-code {
    font-family: monospace;
    color: theme(brand, main);
    background-color: opacify(theme(brand, main), 0.5);
  }
  .tag-h1,
  .tag-h2,
  .tag-h3,
  .tag-h4 {
    margin: spacing(2) 0;
  }
  .tag-link {
    color: theme(neutral, 50);
    text-decoration: none;
    transition: color theme(animation, duration) ease-in-out;

    &:hover {
      color: theme(brand, main);
    }
  }

  .tag-h1 {
    font-size: xx-large;
  }
  .tag-h2 {
    font-size: x-large;
  }
  .tag-h3 {
    font-size: large;
  }

  .tag-h4 {
    font-size: normal;
  }
  .tag-indent {
    margin-top: spacing(2);
    padding-left: spacing(2);
    border-left: 2px solid opacify(theme(brand, main), 0.1);
  }
}

.tag-div {
  margin-bottom: spacing(4);
}
