.bordered-collapsible-container {
  border-radius: radius(1);
  padding: spacing(1);
  position: relative;
  transition: all 0.3s ease;

  .collapsible-head {
    display: flex;
    gap: spacing(2);
  }

  summary {
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    display: flex;
    text-align: left;
    color: theme(text-color, main);
    border-radius: radius(1);
    background: transparent;
    padding: spacing(2);
  }
  &[open] {
    summary {
      background: transparentize(theme(neutral, 100), 0.96);
    }
    .collapsible-head-icon {
      transform: rotate(90deg);
    }
  }

  .collapsible-head-icon {
    position: absolute;
    right: spacing(3);
    top: spacing(4.5);
    transition: 0.2s ease-in;
    transform: rotate(-90deg);
  }
}
