.signup-methods {
  a {
    margin-top: spacing(4);

    svg {
      margin-right: spacing(2);

      path {
        fill: theme(neutral, 50);
      }
    }
  }
}

.sign-separator {
  color: theme(neutral, 25);
  margin: 16px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 33.333%;
    height: 1px;
    background-color: theme(neutral, 4);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 33.333%;
    height: 1px;
    background-color: theme(neutral, 4);
  }
}

.other-methods {
  color: theme(neutral, 25);
  text-decoration: none;
  font-weight: 700;
  margin-bottom: spacing(4);
}

@media (max-width: $md-max) {
  .btn-red.black.wallet {
    background-color: transparent;
  }
}
