.switch-button {
  padding: spacing(1);
  background-color: theme(neutral, 4);
  position: relative;

  a {
    color: theme(neutral, 50);
    text-decoration: none;
    padding: spacing(1) spacing(4);
    position: relative;
    z-index: 1;
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .background {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: 0;
    background-color: theme(neutral, 12);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.site_light {
  .switch-button {
    background-color: theme(neutral, 50);

    a {
      color: theme(neutral, 2);

      &.active {
        color: theme(neutral, 50);
      }
    }

    &:hover {
      a {
        color: theme(neutral, 2);

        &.active {
          color: theme(neutral, 2);
        }

        &:hover {
          color: theme(neutral, 50);
        }
      }
    }
  }
}
