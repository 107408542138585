// INTEGRAL FC
.integralfc-h1 {
  font-family: var(--font-integral-cf);
  font-size: 64px;
  font-weight: 400;
  line-height: 64px;

  @media (max-width: $sm-max) {
    font-size: 48px;
    font-weight: 400;
    line-height: 48px;
  }
}

.integralfc-h2-l {
  font-size: 54px;
  font-weight: 600;
}

.integralfc-h2 {
  font-family: var(--font-integral-cf);
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;

  @media (max-width: $sm-max) {
    font-size: 32px;
    line-height: 32px;
  }
}

.integralfc-heading-lg {
  font-family: var(--font-integral-cf);
  font-size: 48px;
  font-weight: 600;

  @media (max-width: $sm-max) {
    font-size: 32px;
    line-height: 32px;
  }
}

.integralfc-heading-md {
  font-family: var(--font-integral-cf);
  font-size: 32px;
  font-weight: 600;
}

.integralfc-body-md {
  font-family: var(--font-integral-cf);
  font-size: 24px;
  font-weight: 600;
  line-height: 133.333%;
}

.integralfc-body-sm {
  font-family: var(--font-integral-cf);
  font-size: 20px;
  font-weight: 300;
  line-height: 133.333%;

  @media (max-width: $sm-max) {
    font-size: 16px;
  }
}

.integralfc-body-s {
  font-family: var(--font-integral-cf);
  font-size: 18px;
  font-weight: 600;
  line-height: 18px; /* 100% */
}

.integralfc-body {
  font-family: var(--font-integral-cf);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.integralfc-body-xs {
  font-family: var(--font-integral-cf);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.integralfc-body-xxs {
  font-family: var(--font-integral-cf);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.integralfc-body-xxxs {
  font-family: var(--font-integral-cf);
  font-size: 10px;
}


/* Lexend headings */

.lexend-xl {
  font-family: var(--font-lexend);
  font-size: 64px;
  line-height: 72px;
}

.lexend-heading-m {
  font-family: var(--font-lexend);
  font-size: 16px;
  line-height: 28px;
}

.lexend-heading-s {
  font-family: var(--font-lexend);
  font-size: 14px;
  line-height: 24px;
}

.lexend-heading-xs {
  font-family: var(--font-lexend);
  font-size: 12px;
  line-height: 20px;
}

.lexend-heading-xxs {
  font-family: var(--font-lexend);
  font-size: 10px;
  line-height: 16px;
}

/* Lexend body */

.lexend-body-xl {
  font-family: var(--font-lexend);
  font-size: 24px;
  line-height: 32px;
}

.lexend-body-l {
  font-family: var(--font-lexend);
  font-size: 18px;
  line-height: 133.333%;
}

.lexend-body-m {
  font-family: var(--font-lexend);
  font-size: 16px;
  line-height: 28px;

  @media (max-width: $sm-max) {
    font-family: var(--font-lexend);
    font-size: 14px;
    line-height: 24px;
  }
}

.lexend-body-s {
  font-family: var(--font-lexend);
  font-size: 14px;
  line-height: 24px;
}

.lexend-body-xs1 {
  font-family: var(--font-lexend);
  font-size: 12px;
  line-height: 20px;
}

.lexend-body-xs2 {
  font-family: var(--font-lexend);
  font-size: 12px;
  line-height: 14px;
}

.lexend-body-xxs {
  font-family: var(--font-lexend);
  font-size: 10px;
  line-height: 16px;
}

h1.lexend-heading,
.lexend-heading-h1 {
  font-family: var(--font-lexend);
  font-size: 48px;
  line-height: 56px;
}

h2.lexend-heading,
.lexend-heading-h2 {
  font-family: var(--font-lexend);
  font-size: 32px;
  line-height: 48px;
}

h3.lexend-heading,
.lexend-heading-h3 {
  font-family: var(--font-lexend);
  font-size: 24px;
  line-height: 40px;
}

h4.lexend-heading-l {
  font-family: var(--font-lexend);
  font-size: 18px;
  line-height: 28px;
}

.link {
  font-family: var(--font-lexend);
  position: relative;
  color: theme(brand, main);
  transition: 0.2s ease-in;

  &:hover {
    color: theme(neutral, 50);
    text-decoration: none;
  }
}

.font-bold {
  font-weight: bold;
}
.font-normal {
  font-weight: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-secondary {
  color: theme(neutral, 25);
}

.all-caps {
  text-transform: uppercase;
}
.text-balance {
  text-align: balance;
}

.text-ellipsis {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  min-width: 0;
}
.vert-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
