@import "@/styles/base";

.guest_user_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-start: spacing(8);
}

.participants {
    color: theme(neutral, 50);

    /* Lexend/Body/Medium/XS */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
