@import '@/styles/base';
.site-brand-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: spacing(1);
}

.site-brand {
  svg {
    width: 32px;
    height: 32px;

    path {
      opacity: 0;
      fill: theme(brand, main);
      transition: 0.2s ease-in;

      &:first-child {
        transform: translateY(30%);
      }

      &:last-child {
        transform: translateY(-30%);
      }
    }
  }
}
