.community-head {
  position: relative;
  height: 405px;
  background-color: theme(neutral, 4);
  grid-area: head-content;

  &-container {
    grid-area: head;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    position: relative;

    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(13, 13, 18, 0) 52.33%,
        #0d0d12 98.58%
      );
      z-index: 2;
    }
  }

  &__community-invitation {
    width: max-content;
    align-self: flex-end;
    cursor: pointer;
  }

  .community-head-details {
    position: relative;
    z-index: 4;
    transition: theme(animation, duration) theme(animation, timing);
    padding: spacing(4);
    width: 100%;
  }

  .community-head-logo {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    margin-right: spacing(4);
    overflow: hidden;
    transition: theme(animation, duration) theme(animation, timing);

    .community-logo {
      transition: theme(animation, duration) theme(animation, timing);
    }

    .react-loading-skeleton {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }

  .level-title {
    color: theme(neutral, 25);
  }

  .community-options {
    margin-bottom: spacing(3);
  }

  .socials {
    margin: 0 spacing(-1);
  }

  .community-head-data {
    h4 {
      font-weight: 700;
      margin-right: spacing(4);

      .react-loading-skeleton {
        min-width: 170px;
      }
    }
  }
}

@media (max-width: $md-max) {
  .community-head {
    .community-head-logo,
    .community-logo {
      width: 72px;
      height: 72px;
    }
  }
}

.community-head-background {
  object-fit: cover;
}
