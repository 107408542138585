.visit-link-mission {
  isolation: isolate;
  padding: spacing(2);
  position: relative;
  margin-top: spacing(2);
  --mask-image: linear-gradient(188deg, #00000070, transparent);

  &:hover {
    --mask-image: linear-gradient(188deg, #000000ba, transparent);
  }

  &:after {
    content: '';
    z-index: -1;
    border-radius: radius(1);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04) 0%,
        rgba(255, 255, 255, 0.04) 100%
      ),
      linear-gradient(
        226deg,
        rgba(255, 255, 255, 0.04) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    border: 1px rgba(255, 255, 255, 0.2) solid;
    position: absolute;
    inset: 0;
    mask-image: var(--mask-image);
  }
}
